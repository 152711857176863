// controllers
const AFFILIATE_CHANNELS = 'affiliate-channels';
const AFFILIATE_CHANNELS_TREES = 'affiliate-channel-trees';
const AFFILIATES = 'affiliates';
const AUTHENTICATION = 'authentication';
const BWLISTS = 'bwlists';
const CAMPAIGNS = 'campaigns';
const DATA = 'data';
const DOCUMENT = 'documents';
const EXTERNAL_SERVICES = 'external-services';
const GENERAL = 'general';
const FUNDINGS = 'fundings';
const LEADS = 'leads';
const MANUAL_REVIEWS = 'manual-reviews';
const NOTIFICATIONS = 'notifications';
const REPORTS = 'reports';
const SETTINGS = 'settings';
const SLACK = 'slack';
const TAGS = 'tags';
const TENANT = 'tenant';
const TENANT_USER = 'tenant-user';
const THIRD_PARTY_API = 'third-party-api';
const VALIDATION_TREE_GET = 'affiliate-channel-tree-actions';
const AAN_REASON = 'aan-reason';

// affiliates
export const API_AFFILIATE = (payload?: string | number, extra?: string): string =>
  `/${AFFILIATES}/${payload ?? ''}${extra ?? ''}`;
export const API_AFFILIATE_STATUS = (id: string, status: number): string =>
  `/${AFFILIATES}/${id}/status?status=${status}`;
export const API_AFFILIATE_CHANNELS = (payload?: string | number, extra?: string): string =>
  `/${AFFILIATE_CHANNELS}/${payload ?? ''}${extra ?? ''}`;
export const API_AFFILIATE_CHANNEL_STATUS = (id: string, status: number): string =>
  `/${AFFILIATE_CHANNELS}/${id}/status?status=${status}`;
export const API_AFFILIATE_CHANNEL_BY_AFFILIATE_ID = (id?: string): string =>
  `/${AFFILIATE_CHANNELS}/affiliates/${id ?? ''}`;
export const API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID = (id?: string): string =>
  `/${AFFILIATE_CHANNELS}/campaigns/${id ?? ''}`;
export const API_AFFILIATE_CHANNEL_BY_ID = (id: string): string => `/${AFFILIATE_CHANNELS}/${id}`;
export const API_GET_AFFILIATE_CHANNEL_POSTING_DETAILS_BY_ID = (id: string): string =>
  `/${AFFILIATE_CHANNELS}/${id}/posting-details`;
export const API_CREDIT_UNIONS = (): string => `${DATA}/credit-unions`;
export const API_GET_DATA_FORMATS = (): string => `/${DATA}/data-formats`;
export const API_GET_HISTORY_AFFILIATE_CHANNELS = (id?: string): string => `/${AFFILIATE_CHANNELS}/${id}/history`;
export const API_PATCH_AFFILIATE_CHANNEL_RESET_PASSWORD = (id: string): string =>
  `/${AFFILIATE_CHANNELS}/resetpassword?id=${id}`;
export const API_SIMPLIFIED_AFFILIATE = (): string => `/${AFFILIATES}/simplified_list`;
export const API_SIMPLIFIED_AFFILIATE_CHANNELS = (): string => `/${AFFILIATE_CHANNELS}/simplified_list`;
// API_EXTERNAL_SERVICES - reused from externalServices

// auth
export const API_RECOVERY_PASSWORD = (): string => `/${AUTHENTICATION}/recoverypassword`;
export const API_RECOVERY_PASSWORD_REQUEST = (): string => `/${AUTHENTICATION}/recovery-password-request`;
export const API_SET_PASSWORD = (): string => `/${AUTHENTICATION}/user-set-password`;
export const API_SIGNIN = (): string => `/${AUTHENTICATION}/signin`;
export const API_SIGNOUT = (): string => `/${AUTHENTICATION}/signout`;

// campaigns
export const API_CAMPAIGNS = (payload?: string | number, extra?: string): string =>
  `/${CAMPAIGNS}/${payload ?? ''}${extra ?? ''}`;
export const API_CAMPAIGN_BY_ID = (id: string): string => `/${CAMPAIGNS}/${id}`;
export const API_CAMPAIGN_FIELDS = (id: string): string => `/${CAMPAIGNS}/${id}/fields`;
export const API_GET_DATA_TIME_FORMATS = (): string => `/${DATA}/datetime-formats`;
export const API_GET_HISTORY_CAMPAIGNS = (id?: string): string => `/${CAMPAIGNS}/${id}/history`;
export const API_GET_VALIDATORS = (): string => `${DATA}/validators`;
export const API_SIMPLIFIED_CAMPAIGNS = (): string => `/${CAMPAIGNS}/simplified_list`;
// API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID - reused from affiliates
// API_FIELDS - reused from externalServices

// countries
export const API_GET_COUNTRIES = (): string => `${DATA}/countries`;
export const API_STATE_PROVINCES = (id: string): string => `/${DATA}/countries/${id}/state-provinces`;

// dashboard
export const API_GET_COMPARISON_REPORTS = (): string => `${REPORTS}/comparison/`;
export const API_GET_EXTERNAL_SERVICES_REPORTS = (): string => `/${REPORTS}/external-services/`;
export const API_GET_LEADS_REPORTS = (payload?: string): string => `/${REPORTS}/leads/?${payload ?? ''}`;
export const API_GET_NO_LEADS_REPORTS = () => `/${REPORTS}/no-leads/`;
export const API_GET_TOP_LOCATIONS_REPORTS = (): string => `${REPORTS}/top-locations/`;

// externalServices
export const API_EXTERNAL_SERVICES = (payload?: string): string => `/${EXTERNAL_SERVICES}/${payload ?? ''}`;
export const API_EXTERNAL_SERVICES_CAMPAIGN_FIELDS = (id: string, extra?: string): string =>
  `/${EXTERNAL_SERVICES}/${id}/campaign-fields${extra ?? ''}`;
export const API_EXTERNAL_SERVICES_FIELDS = (id: string): string => `/${EXTERNAL_SERVICES}/${id}/fields`;
export const API_EXTERNAL_SERVICES_RESPONSE_FIELDS = (id: string): string =>
  `/${EXTERNAL_SERVICES}/${id}/response-fields`;
export const API_FIELDS = () => `/${GENERAL}/fields`;
export const API_SIMPLIFIED_EXTERNAL_SERVICES = (): string => `/${EXTERNAL_SERVICES}/simplified_list`; // @deprecated

// funding
export const API_ACTIVE_USERS = (role: number): string => `/${TENANT_USER}/active-users?role=${role}`;
export const API_ASSIGN_FUNDER = (fundId: string, userId: number | string) =>
  `${FUNDINGS}/${fundId}/assign-reviewer/${userId}`;
export const API_DOWNLOAD_FUNDING_REPORT = (): string => `/${FUNDINGS}/download-funding-report`;
export const API_FUNDINGS = (): string => `/${FUNDINGS}`;
export const API_FUNDING_DOCUMENT_DELETE = (id: string): string => `/${FUNDINGS}/documents/${id}`;

// leads
export const API_DOCUMENT_DOWNLOAD = (id: string): string => `/${DOCUMENT}/${id}/download`;
export const API_DOCUMENT_LIST = (id: string): string => `/${DOCUMENT}/${id}/list`;
export const API_DOCUMENT_TYPES = (): string => `/${DOCUMENT}/categories`;
export const API_DOCUMENT_UPLOAD = (): string => `/${DOCUMENT}/upload`;
export const API_GET_LEAD_FUNDS = (id: string, extra?: string): string => `/${LEADS}/${id}/funds/${extra ?? ''}`;
export const API_GET_LEAD_LOGS = (id: string): string => `/${LEADS}/${id}/logs`;
export const API_LEAD_FUNDS_STATUS_CHANGE = (): string => `/${LEADS}/funds`;
export const API_LEADS = (payload?: string | number): string => `/${LEADS}/${payload ?? ''}`;
export const API_LEADS_BY_ID = (id: string): string => `/${LEADS}/${id}`;
export const API_LEADS_JOURNEY = (id: string): string => `/${LEADS}/${id}/journey`;
export const API_LEADS_STEP_STATUSES = (id: string): string => `/${LEADS}/step-statuses/${id}`;
export const API_LEADS_RERUN = (id: string, stepName: string): string => `/${LEADS}/${id}/${stepName}/rerun`;
export const API_LEADS_RERUN_STEPS = (id: string): string => `/${LEADS}/${id}/rerun-steps`;
export const API_MANUAL_REVIEW_UPLOAD = (id: string): string => `/${MANUAL_REVIEWS}/reasons/${id}/upload`;
export const API_MANUAL_REVIEWS_REVERT = (): string => `/${MANUAL_REVIEWS}/revert`;
export const API_APPLICATION_JOURNEY_RESEND_EMAIL = (id: string): string => `/${LEADS}/${id}/email`;
export const API_MANUAL_REVIEW_DOCUMENT_SUBTYPES = (id: string): string => `/${MANUAL_REVIEWS}/${id}/documenttypes`;
export const API_MANUAL_REVIEW_DOCUMENT_DELETE = (id: string): string => `/${MANUAL_REVIEWS}/documents/${id}`;
export const API_LEADS_ADD_NOTE = (): string => `/${LEADS}/add/note`;
export const API_LEADS_NOTES = (pageNumber: number, pageSize: number, leadId: string, userId?: string | null): string =>
  `/${LEADS}/notes?SearchModel.PageNumber=${pageNumber}&SearchModel.PageSize=${pageSize}&LeadId=${leadId}${
    userId ? `&UserId=${userId}` : ''
  }`; // Need to discuss and refactor
export const API_LEADS_NOTE = (): string => `/${LEADS}/note`;
export const API_FUNDING_UPLOAD = (id: string): string => `/${FUNDINGS}/${id}/upload`;
export const API_APPLICATION_CANCEL = (): string => `/${LEADS}/cancel`;

// lists
export const API_ACTION_WITH_BWLIST_VALUE = (id: string) => `/${BWLISTS}/values/${id}`;
export const API_DELETE_LISTS = (id: string): string => `/${BWLISTS}/${id}/values/unload`;
export const API_GET_LISTS = (payload?: string | number, extra?: string | number): string =>
  `/${BWLISTS}/list-type/${payload ?? ''}${extra ?? ''}`; // @deprecated
export const API_GET_LIST_BY_ID = (id?: string): string => `/${BWLISTS}/${id ?? ''}`;
export const API_GET_LISTS_VALUES_BY_ID = (id: string): string => `/${BWLISTS}/${id}/values`;
export const API_GET_UPLOADED_LISTS = (id: string): string => `/${BWLISTS}/${id}/values/upload`;
export const API_POST_BWLIST_VALUES = () => `/${BWLISTS}/values`;
export const API_POST_LISTS = (): string => `/${BWLISTS}`;

// manualReviews
export const API_ASSIGN_REVIEWER = (reasonId: string) => `${MANUAL_REVIEWS}/reasons/${reasonId}/assign-reviewer`;
export const API_LEADS_MANUAL_REVIEW = (id: string): string => `/${LEADS}/${id}/manual-review`;
export const API_LEADS_MANUAL_REVIEW_REASON = (id: string): string => `/${MANUAL_REVIEWS}/reasons/${id}`;
export const API_LEADS_MANUAL_REVIEW_RESOLVE = (id: string): string => `/${MANUAL_REVIEWS}/reasons/${id}/resolve`;
export const API_MANUAL_REVIEW_REASONS_SUBMIT = (id: string): string => `/${MANUAL_REVIEWS}/reasons/${id}/submit`;
export const API_MANUAL_REVIEWS = (): string => `/${MANUAL_REVIEWS}`;
export const API_MERCHANT_NAMES = (): string => `/${LEADS}/merchant-names`;
export const API_UNDERWRITERS = (): string => `/${TENANT_USER}/underwriters`;
export const API_MANUAL_REVIEWS_SOLAR_PANEL_DETAILS = (reasonId: string): string =>
  `/${MANUAL_REVIEWS}/${reasonId}/solar-panel-details`;

// notifications
export const API_GENERAL_COUNTS = (): string => `/${GENERAL}/counts`;
export const API_MARK_ALL_AS_READ = (): string => `/${NOTIFICATIONS}/markallasread`;
export const API_MARK_AS_READ = (id: string): string => `/${NOTIFICATIONS}/markasread/${id}`;
export const API_NOTIFICATIONS = (id?: string): string => `/${NOTIFICATIONS}${id ? `?notificationId=${id}` : ''}`;

// reports
export const API_DOWNLOAD_LEAD_LIST_REPORT = (): string => `/${LEADS}/report`;
export const API_GET_AFFILIATE_COMPARISON_REPORTS = (): string => `/${REPORTS}/affiliate-comparison`;
export const API_GET_AFFILIATE_REPORTS = (): string => `/${REPORTS}/affiliate`;

// settings
export const API_SETTINGS_MFA = (enable: boolean, code?: string): string =>
  `/${SETTINGS}/mfa?enable=${enable}${code ? `&userEnteredCode=${code}` : ''}`;
export const API_SETTINGS_MFA_INIT = (): string => `/${SETTINGS}/mfa/init`;
export const API_SETTINGS_MFA_STATUS = () => `/${SETTINGS}/mfastatus`;
export const API_SLACK_INSTALL = (): string => `/${SLACK}/install`;
export const API_TENANT_SETTINGS = (): string => `/${SETTINGS}/tenantsettings`;
export const API_TENANT_SETTINGS_RESET = (): string => `/${SETTINGS}/reset-daily-cap`;
export const API_USER_SETTINGS = (): string => `/${SETTINGS}/usersettings`;

// systemFields
export const API_SYSTEM_FIELDS = () => `/${GENERAL}/system-fields`;

// tags
export const API_TAGS = (): string => `/${TAGS}`;
export const API_TAGS_CHANGE = (id: string): string => `/${TAGS}/id?id=${id}`;
export const API_TAGS_REMOVE = (): string => `/${TAGS}/remove`;
export const API_TAGS_TYPES = (): string => `/${TAGS}/type`;

// tenant
export const API_TENANT = () => `/${TENANT}/logo`;

// userManagement
export const API_DELETE_USER = (id: string): string => `/${AUTHENTICATION}/users/${id}`;
export const API_TENANT_USERS = () => `/${AUTHENTICATION}/tenant-users`;
export const API_USER_ACTIVATE = (id: string) => `/${AUTHENTICATION}/users/${id}/activate`;
export const API_USER_DEACTIVATE = (id: string) => `/${AUTHENTICATION}/users/${id}/deactivate`;
export const API_USER_EDIT_ROLE = (id: string) => `/${AUTHENTICATION}/users/${id}`;
export const API_USER_FORCE_RESET_PASSWORD = () => `/${AUTHENTICATION}/force-reset-password`;
export const API_USER_INFO_BY_TOKEN = (token: string) => `/${AUTHENTICATION}/invitation-info?token=${token}`;
export const API_USER_INVITE = () => `/${AUTHENTICATION}/invite-user`;
export const API_USER_INVITE_RESEND = () => `/${AUTHENTICATION}/users/resend-invitation`;
export const API_GET_PERMISSIONS_BY_ID = (id: string) => `/permissions/${id}`;
export const API_GET_PERMISSIONS = () => `/permissions`;
export const API_GET_ROLES = () => `/roles`;
export const API_GET_ROLE_BY_ID = (id: string) => `/roles/${id}`;

// validationTree
export const API_AFFILIATE_CHANNELS_ACTIONS = (id: string): string => `/${VALIDATION_TREE_GET}/${id}`;
export const API_AFFILIATE_CHANNELS_ACTIONS_GET = (id: string): string => `/${AFFILIATE_CHANNELS_TREES}/${id}/list`;
export const API_AFFILIATE_CHANNELS_TREE_CHANGE = (id: string): string => `${AFFILIATE_CHANNELS_TREES}/${id}`;
export const API_AFFILIATE_CHANNELS_TREES = (payload?: number | string, extra?: string): string =>
  `/${AFFILIATE_CHANNELS_TREES}/${payload ?? ''}${extra ?? ''}`;
export const API_AFFILIATE_CHANNELS_TREES_GET = (id: string, extra?: string): string =>
  `/${AFFILIATE_CHANNELS_TREES}/${id}${extra ?? ''}`;
export const API_GET_CONDITIONS = (payload?: string): string => `/${DATA}/conditions${payload ?? ''}`;
export const API_MANUAL_REVIEW_REASONS_LIST = (): string => `/${MANUAL_REVIEWS}/reasons/names/list`;
export const API_THIRD_PARTY_API = (): string => `/${THIRD_PARTY_API}/response-fields/Experian`;
export const API_VALIDATION_TREE_GET = (id?: string): string => `/${VALIDATION_TREE_GET}/${id ?? ''}`;
export const API_VALIDATION_TREE_TYPES = (): string => `/${DATA}/decision-tree-types`;

// aan reason
export const API_AAN_REASON = (reasonId?: string): string => `/${AAN_REASON}?manualReviewReason=${reasonId}`;
export const API_AAN_REASON_CREATE = (): string => `/${AAN_REASON}`;
