import AddNoteModal, { IAddNoteModalProps } from 'components/Modal/Leads/AddNoteModal';
import DeleteNoteModal, { IDeleteNoteModalProps } from 'components/Modal/Leads/DeleteNoteModal';
import CreateReasonModal, { ICreateReasonModalProps } from 'components/Modal/ManualReview/CreateReasonModal';
import DeleteFileModal, { IDeleteFileModalProps } from 'components/Modal/ManualReview/DeleteFileModal';
import ReasonUploadFilesModal, {
  IReasonUploadFilesModalProps,
} from 'components/Modal/ManualReview/ReasonUploadFilesModal';
import SolarPanelEditModal, { ISolarPanelEditModalProps } from 'components/Modal/ManualReview/SolarPanelEditModal';
import EditUserRoleModal, { IEditUserRoleModalProps } from 'components/Modal/UserManagement/EditUserRoleModal';
import { useAppSelector } from 'redux/hooks';

import { viewsSelector } from '../../redux/slices/views';
import { IOpenedModal, ModalName } from '../../redux/slices/views/initialState';
import { IHistory } from '../../types/campaigns';
import ConfirmAffiliateChannelDeleteModal, {
  IConfirmAffiliateChannelDeleteModalProps,
} from '../Modal/Affiliate/ConfirmAffiliateChannelDeleteModal';
import ConfirmAffiliateChannelEditSaveModal, {
  IConfirmAffiliateChannelEditSaveModalProps,
} from '../Modal/Affiliate/ConfirmAffiliateChannelEditSaveModal';
import ConfirmAffiliateChannelHistoryModal from '../Modal/Affiliate/ConfirmAffiliateChannelHistoryModal';
import ConfirmAffiliateDeleteModal, {
  IConfirmAffiliateDeleteModalProps,
} from '../Modal/Affiliate/ConfirmAffiliateDeleteModal';
import ConfirmAffiliateDetailsSaveChangesModal, {
  IConfirmAffiliateDetailsSaveChangesModalProps,
} from '../Modal/Affiliate/ConfirmAffiliateDetailsSaveChangesModal';
import ConfirmAffiliateChannelCreateSuccessModal from '../Modal/AffiliateChannelTrees/ConfirmAffiliateChannelCreateSuccessModal';
import ConfirmAffiliateChannelTreeDeleteModal, {
  IConfirmAffiliateChannelTreeDeleteModalProps,
} from '../Modal/AffiliateChannelTrees/ConfirmAffiliateChannelTreeDeleteModal';
import ConfirmAffiliateChannelTreeNameModal, {
  IConfirmAffiliateChannelTreeNameModalProps,
} from '../Modal/AffiliateChannelTrees/ConfirmAffiliateChannelTreeNameModal';
import ConfirmCampaignCloneModal, {
  IConfirmCampaignCloneModalProps,
} from '../Modal/Campaign/ConfirmCampaignCloneModal';
import ConfirmCampaignDeleteModal, {
  IConfirmCampaignDeleteModalProps,
} from '../Modal/Campaign/ConfirmCampaignDeleteModal';
import ConfirmCampaignHistoryModal, {
  IConfirmCampaignHistoryModalProps,
} from '../Modal/Campaign/ConfirmCampaignHistoryModal';
import ConfirmCreateCampaignModal from '../Modal/Campaign/ConfirmCreateCampaignModal';
import ConfirmExternalServiceDeleteModal, {
  IConfirmExternalServiceDeleteModalProps,
} from '../Modal/ExternalService/ConfirmExternalServiceDeleteModal';
import ConfirmExternalServiceSuccessModal, {
  IConfirmExternalServiceSuccessModalProps,
} from '../Modal/ExternalService/ConfirmExternalServiceSuccessModal';
import FundingRequestRejectModal, {
  IFundingRequestRejectModalProps,
} from '../Modal/FundingRequests/FundingRequestRejectModal';
import CancelApplicationModal, { ICancelApplicationModalProps } from '../Modal/Leads/CancelApplicationModal';
import DocumentsUploadFilesModal, { IDocumentsUploadFilesModalProps } from '../Modal/Leads/DocumentsUploadFilesModal';
import RerunModal, { IRerunModalProps } from '../Modal/Leads/RerunModal';
import ResendDocumentModal from '../Modal/Leads/ResendDocumentsModal';
import RevertApplicationModal, { IRevertApplicationModalProps } from '../Modal/Leads/RevertApplicationModal';
import ConfirmListIntegrationAddModal, {
  IConfirmListIntegrationAddModalProps,
} from '../Modal/ListIntegration/ConfirmListIntegrationAddModal';
import ConfirmListIntegrationDeleteModal, {
  IConfirmListIntegrationDeleteModalProps,
} from '../Modal/ListIntegration/ConfirmListIntegrationDeleteModal';
import ConfirmListIntegrationDetailsAddModal, {
  IConfirmListIntegrationDetailsAddModalProps,
} from '../Modal/ListIntegration/ConfirmListIntegrationDetailsAddModal';
import RejectApplicantModal, { IRejectApplicantModalProps } from '../Modal/ManualReview/RejectApplicantModal';
import ShareManualReviewModal, { IShareManualReviewModalProps } from '../Modal/ManualReview/ShareManualReviewModal';
import AddPromotionModal, { IAddPromotionModalProps } from '../Modal/OfferTree/AddPromotionModal';
import DeleteOutboundAPIModal, { IDeleteOutboundAPIModal } from '../Modal/Outbound/DeleteOutboundAPIModal';
import PostedToAPIModal from '../Modal/Outbound/PostedToAPIModal';
import TurnOnIntegrationModal, { ITurnOnIntegrationModal } from '../Modal/Outbound/TurnOnIntegrationModal';
import ResetDailyCapModal from '../Modal/Settings/ResetDailyCapModal';
import TwoFactorAuthenticationDisableModal, {
  ITwoFactorAuthenticationDisableModalProps,
} from '../Modal/Settings/TwoFactorAuthenticationDisableModal';
import TwoFactorAuthenticationModal from '../Modal/Settings/TwoFactorAuthenticationModal';
import TwoFactorAuthenticationResultModal, {
  ITwoFactorAuthenticationResultModalProps,
} from '../Modal/Settings/TwoFactorAuthenticationResultModal';
import DeleteTagModal, { IDeleteTagModalProps } from '../Modal/Tags/DeleteTagModal';
import CreateRoleModal, { ICreateRoleModalProps } from '../Modal/UserManagement/CreateRoleModal';
import DeactivateUserModal, { IDeactivateUserModalProps } from '../Modal/UserManagement/DeactivateUserModal';
import DeleteUserModal, { IDeleteUserModalProps } from '../Modal/UserManagement/DeleteUserModal';
import DomainRestrictionsModal from '../Modal/UserManagement/DomainRestrictionsModal';
import EditRoleModal, { IEditRoleModalProps } from '../Modal/UserManagement/EditRoleModal';
import DeleteRoleModal, { IDeleteRoleModalProps } from '../Modal/UserManagement/hooks/DeleteRoleModal';
import InviteNewUserModal from '../Modal/UserManagement/InviteNewUserModal';
import ResendInviteUserModal, { IResendInviteUserModalProps } from '../Modal/UserManagement/ResendInviteUserModal';
import ResetPasswordModal, { IResetPasswordModalProps } from '../Modal/UserManagement/ResetPasswordModal';
import AanModal, { IAanModalProps } from '../Modal/ValidationTree/AanModal';
import CapModal, { ICapModalProps } from '../Modal/ValidationTree/CapModal';
import DescriptionModal, { IDescriptionModalProps } from '../Modal/ValidationTree/DescriptionModal';
import PauseModal, { IPauseModalProps } from '../Modal/ValidationTree/PauseModal';
import ScheduleModal, { IScheduleModalProps } from '../Modal/ValidationTree/ScheduleModal';
import XMLModal, { IXMLModalProps } from '../Modal/XmlModal/XMLModal';

const getConfirmCampaignDeleteModal = (modal: IOpenedModal<IConfirmCampaignDeleteModalProps>) => (
  <ConfirmCampaignDeleteModal key={modal.name} {...modal.props} />
);
const getConfirmAffiliateDeleteModal = (modal: IOpenedModal<IConfirmAffiliateDeleteModalProps>) => (
  <ConfirmAffiliateDeleteModal key={modal.name} {...modal.props} />
);
const getConfirmAffiliateChannelDeleteModal = (modal: IOpenedModal<IConfirmAffiliateChannelDeleteModalProps>) => (
  <ConfirmAffiliateChannelDeleteModal key={modal.name} {...modal.props} />
);
const getConfirmExternalServiceDeleteModal = (modal: IOpenedModal<IConfirmExternalServiceDeleteModalProps>) => (
  <ConfirmExternalServiceDeleteModal key={modal.name} {...modal.props} />
);
const getConfirmAffiliateChannelTreeDeleteModal = (
  modal: IOpenedModal<IConfirmAffiliateChannelTreeDeleteModalProps>,
) => <ConfirmAffiliateChannelTreeDeleteModal key={modal.name} {...modal.props} />;

const getConfirmAffiliateChannelCreateSuccessModal = (modal: IOpenedModal<null>) => (
  <ConfirmAffiliateChannelCreateSuccessModal key={modal.name} />
);
const getConfirmAffiliateChannelRuleNameModal = (modal: IOpenedModal<IConfirmAffiliateChannelTreeNameModalProps>) => (
  <ConfirmAffiliateChannelTreeNameModal key={modal.name} {...modal.props} />
);

const getConfirmListIntegrationDeleteModal = (modal: IOpenedModal<IConfirmListIntegrationDeleteModalProps>) => (
  <ConfirmListIntegrationDeleteModal key={modal.name} {...modal.props} />
);
const getConfirmListIntegrationAddModal = (modal: IOpenedModal<IConfirmListIntegrationAddModalProps>) => (
  <ConfirmListIntegrationAddModal key={modal.name} {...modal.props} />
);
const getConfirmCampaignHistoryModal = (modal: IOpenedModal<IConfirmCampaignHistoryModalProps>) => (
  <ConfirmCampaignHistoryModal key={modal.name} {...modal.props} />
);
const getConfirmAffiliateDetailsSaveChangesModal = (
  modal: IOpenedModal<IConfirmAffiliateDetailsSaveChangesModalProps>,
) => <ConfirmAffiliateDetailsSaveChangesModal key={modal.name} {...modal.props} />;

const getConfirmExternalServiceSuccessModal = (modal: IOpenedModal<IConfirmExternalServiceSuccessModalProps>) => (
  <ConfirmExternalServiceSuccessModal key={modal.name} {...modal.props} />
);

const getConfirmListIntegrationDetailsAddModal = (modal: IOpenedModal<IConfirmListIntegrationDetailsAddModalProps>) => (
  <ConfirmListIntegrationDetailsAddModal key={modal.name} {...modal.props} />
);

const getConfirmAffiliateChannelEditSaveModal = (modal: IOpenedModal<IConfirmAffiliateChannelEditSaveModalProps>) => (
  <ConfirmAffiliateChannelEditSaveModal key={modal.name} {...modal.props} />
);

const getConfirmCampaignCloneModal = (modal: IOpenedModal<IConfirmCampaignCloneModalProps>) => (
  <ConfirmCampaignCloneModal key={modal.name} {...modal.props} />
);

const getConfirmCreateCampaignModal = (modal: IOpenedModal<null>) => <ConfirmCreateCampaignModal key={modal.name} />;

const getAddPromotionModal = (modal: IOpenedModal<IAddPromotionModalProps>) => (
  <AddPromotionModal key={modal.name} {...modal.props} />
);

const getInviteNewUserModal = (modal: IOpenedModal<null>) => <InviteNewUserModal key={modal.name} />;

const getResendInviteUserModal = (modal: IOpenedModal<IResendInviteUserModalProps>) => (
  <ResendInviteUserModal key={modal.name} {...modal.props} />
);

const getEditUserRole = (modal: IOpenedModal<IEditUserRoleModalProps>) => (
  <EditUserRoleModal key={modal.name} {...modal.props} />
);

const getDeactivateUserModal = (modal: IOpenedModal<IDeactivateUserModalProps>) => (
  <DeactivateUserModal key={modal.name} {...modal.props} />
);

const getResetPasswordModal = (modal: IOpenedModal<IResetPasswordModalProps>) => (
  <ResetPasswordModal key={modal.name} {...modal.props} />
);

const getRejectApplicantModal = (modal: IOpenedModal<IRejectApplicantModalProps>) => (
  <RejectApplicantModal key={modal.name} {...modal.props} />
);

const getShareManualReviewModal = (modal: IOpenedModal<IShareManualReviewModalProps>) => (
  <ShareManualReviewModal key={modal.name} {...modal.props} />
);

const getTurnOnIntegrationModal = (modal: IOpenedModal<ITurnOnIntegrationModal>) => (
  <TurnOnIntegrationModal key={modal.name} {...modal.props} />
);

const getPostedToAPIModal = (modal: IOpenedModal<null>) => <PostedToAPIModal key={modal.name} />;

const getDeleteOutboundAPIModal = (modal: IOpenedModal<IDeleteOutboundAPIModal>) => (
  <DeleteOutboundAPIModal key={modal.name} {...modal.props} />
);

const getScheduleModal = (modal: IOpenedModal<IScheduleModalProps>) => (
  <ScheduleModal key={modal.name} {...modal.props} />
);

const getCapModal = (modal: IOpenedModal<ICapModalProps>) => <CapModal key={modal.name} {...modal.props} />;

const getAanModal = (modal: IOpenedModal<IAanModalProps>) => <AanModal key={modal.name} {...modal.props} />;

const getDescriptionModal = (modal: IOpenedModal<IDescriptionModalProps>) => (
  <DescriptionModal key={modal.name} {...modal.props} />
);

const getPauseModal = (modal: IOpenedModal<IPauseModalProps>) => <PauseModal key={modal.name} {...modal.props} />;

const getFundingRequestRejectModal = (modal: IOpenedModal<IFundingRequestRejectModalProps>) => (
  <FundingRequestRejectModal key={modal.name} {...modal.props} />
);

const getAffiliateChannelHistoryModal = (modal: IOpenedModal<any>) => (
  <ConfirmAffiliateChannelHistoryModal key={modal.name} {...modal.props} />
);

const getTwoFactorAuthenticationModal = (modal: IOpenedModal<null>) => (
  <TwoFactorAuthenticationModal key={modal.name} />
);

const getTwoFactorAuthenticationDisableModal = (modal: IOpenedModal<ITwoFactorAuthenticationDisableModalProps>) => (
  <TwoFactorAuthenticationDisableModal key={modal.name} {...modal.props} />
);

const getTwoFactorAuthenticationResultModal = (modal: IOpenedModal<ITwoFactorAuthenticationResultModalProps>) => (
  <TwoFactorAuthenticationResultModal key={modal.name} {...modal.props} />
);
const getDocumentsUploadFilesModal = (modal: IOpenedModal<IDocumentsUploadFilesModalProps>) => (
  <DocumentsUploadFilesModal key={modal.name} {...modal.props} />
);

const getXMLModal = (modal: IOpenedModal<IXMLModalProps>) => <XMLModal key={modal.name} {...modal.props} />;

const getDeleteTagModal = (modal: IOpenedModal<IDeleteTagModalProps>) => (
  <DeleteTagModal key={modal.name} {...modal.props} />
);

const getRerunModal = (modal: IOpenedModal<IRerunModalProps>) => <RerunModal key={modal.name} {...modal.props} />;

const getRevertApplicationModal = (modal: IOpenedModal<IRevertApplicationModalProps>) => (
  <RevertApplicationModal key={modal.name} {...modal.props} />
);

const getDeleteUserModal = (modal: IOpenedModal<IDeleteUserModalProps>) => (
  <DeleteUserModal key={modal.name} {...modal.props} />
);

const getResetDailyCapLimitModal = (modal: IOpenedModal<null>) => <ResetDailyCapModal key={modal.name} />;

const getDomainRestrictionsModal = (modal: IOpenedModal<null>) => <DomainRestrictionsModal key={modal.name} />;

const getResendDocumentModal = (modal: IOpenedModal<null>) => <ResendDocumentModal key={modal.name} />;

const getCreateRoleModal = (modal: IOpenedModal<ICreateRoleModalProps>) => (
  <CreateRoleModal key={modal.name} {...modal.props} />
);

const getEditRoleModal = (modal: IOpenedModal<IEditRoleModalProps>) => (
  <EditRoleModal key={modal.name} {...modal.props} />
);

const getDeleteRoleModal = (modal: IOpenedModal<IDeleteRoleModalProps>) => (
  <DeleteRoleModal key={modal.name} {...modal.props} />
);

const getAddNoteModal = (modal: IOpenedModal<IAddNoteModalProps>) => <AddNoteModal key={modal.name} {...modal.props} />;

const getDeleteNoteModal = (modal: IOpenedModal<IDeleteNoteModalProps>) => (
  <DeleteNoteModal key={modal.name} {...modal.props} />
);

const getReasonUploadFilesModal = (modal: IOpenedModal<IReasonUploadFilesModalProps>) => (
  <ReasonUploadFilesModal key={modal.name} {...modal.props} />
);

const getSolarPanelEditModal = (modal: IOpenedModal<ISolarPanelEditModalProps>) => (
  <SolarPanelEditModal key={modal.name} {...modal.props} />
);

const getDeleteFileModal = (modal: IOpenedModal<IDeleteFileModalProps>) => (
  <DeleteFileModal key={modal.name} {...modal.props} />
);

const getCreateReasonModal = (modal: IOpenedModal<ICreateReasonModalProps>) => (
  <CreateReasonModal key={modal.name} {...modal.props} />
);

const getCancelApplicationModal = (modal: IOpenedModal<ICancelApplicationModalProps>) => (
  <CancelApplicationModal key={modal.name} {...modal.props} />
);

export const ModalsController = () => {
  const modals = useAppSelector(viewsSelector.modals);

  return (
    <>
      {modals.map((modal: IOpenedModal<unknown>) => {
        switch (modal.name) {
          case ModalName.ConfirmCampaignDeleteModal:
            return getConfirmCampaignDeleteModal(modal as IOpenedModal<IConfirmCampaignDeleteModalProps>);
          case ModalName.ConfirmAffiliateDeleteModal:
            return getConfirmAffiliateDeleteModal(modal as IOpenedModal<IConfirmAffiliateDeleteModalProps>);
          case ModalName.ConfirmAffiliateChannelDeleteModal:
            return getConfirmAffiliateChannelDeleteModal(
              modal as IOpenedModal<IConfirmAffiliateChannelDeleteModalProps>,
            );
          case ModalName.ConfirmExternalServiceDeleteModal:
            return getConfirmExternalServiceDeleteModal(modal as IOpenedModal<IConfirmExternalServiceDeleteModalProps>);
          case ModalName.ConfirmAffiliateChannelTreeDeleteModal:
            return getConfirmAffiliateChannelTreeDeleteModal(
              modal as IOpenedModal<IConfirmAffiliateChannelTreeDeleteModalProps>,
            );
          case ModalName.ConfirmAffiliateChannelCreateSuccessModal:
            return getConfirmAffiliateChannelCreateSuccessModal(modal as IOpenedModal<null>);
          case ModalName.ConfirmAffiliateChannelRuleNameModal:
            return getConfirmAffiliateChannelRuleNameModal(
              modal as IOpenedModal<IConfirmAffiliateChannelTreeNameModalProps>,
            );
          case ModalName.ConfirmListIntegrationDeleteModal:
            return getConfirmListIntegrationDeleteModal(modal as IOpenedModal<IConfirmListIntegrationDeleteModalProps>);
          case ModalName.ConfirmListIntegrationAddModal:
            return getConfirmListIntegrationAddModal(modal as IOpenedModal<IConfirmListIntegrationAddModalProps>);
          case ModalName.ConfirmCreateCampaignModal:
            return getConfirmCreateCampaignModal(modal as IOpenedModal<null>);
          case ModalName.ConfirmCampaignCloneModal:
            return getConfirmCampaignCloneModal(modal as IOpenedModal<IConfirmCampaignCloneModalProps>);
          case ModalName.ConfirmCampaignHistoryModal:
            return getConfirmCampaignHistoryModal(modal as IOpenedModal<IConfirmCampaignHistoryModalProps>);
          case ModalName.ConfirmListIntegrationDetailsAddModal:
            return getConfirmListIntegrationDetailsAddModal(
              modal as IOpenedModal<IConfirmListIntegrationDetailsAddModalProps>,
            );
          case ModalName.ConfirmAffiliateDetailsSaveChangesModal:
            return getConfirmAffiliateDetailsSaveChangesModal(
              modal as IOpenedModal<IConfirmAffiliateDetailsSaveChangesModalProps>,
            );
          case ModalName.ConfirmExternalServiceSuccessModal:
            return getConfirmExternalServiceSuccessModal(
              modal as IOpenedModal<IConfirmExternalServiceSuccessModalProps>,
            );
          case ModalName.ConfirmAffiliateChannelEditSaveModal:
            return getConfirmAffiliateChannelEditSaveModal(
              modal as IOpenedModal<IConfirmAffiliateChannelEditSaveModalProps>,
            );
          case ModalName.AddPromotionModal:
            return getAddPromotionModal(modal as IOpenedModal<IAddPromotionModalProps>);
          case ModalName.InviteNewUserModal:
            return getInviteNewUserModal(modal as IOpenedModal<null>);
          case ModalName.ResendInviteUserModal:
            return getResendInviteUserModal(modal as IOpenedModal<IResendInviteUserModalProps>);
          case ModalName.EditUserRoleModal:
            return getEditUserRole(modal as IOpenedModal<IEditUserRoleModalProps>);
          case ModalName.DeactivateUserModal:
            return getDeactivateUserModal(modal as IOpenedModal<IDeactivateUserModalProps>);
          case ModalName.ResetPasswordModal:
            return getResetPasswordModal(modal as IOpenedModal<IResetPasswordModalProps>);
          case ModalName.RejectApplicantModal:
            return getRejectApplicantModal(modal as IOpenedModal<IRejectApplicantModalProps>);
          case ModalName.ShareManualReviewModal:
            return getShareManualReviewModal(modal as IOpenedModal<IShareManualReviewModalProps>);
          case ModalName.TurnOnIntegrationModal:
            return getTurnOnIntegrationModal(modal as IOpenedModal<ITurnOnIntegrationModal>);
          case ModalName.PostedToAPIModal:
            return getPostedToAPIModal(modal as IOpenedModal<null>);
          case ModalName.DeleteOutboundAPIModal:
            return getDeleteOutboundAPIModal(modal as IOpenedModal<IDeleteOutboundAPIModal>);
          case ModalName.ScheduleModal:
            return getScheduleModal(modal as IOpenedModal<IScheduleModalProps>);
          case ModalName.CapModal:
            return getCapModal(modal as IOpenedModal<ICapModalProps>);
          case ModalName.AanModal:
            return getAanModal(modal as IOpenedModal<IAanModalProps>);
          case ModalName.DescriptionModal:
            return getDescriptionModal(modal as IOpenedModal<IDescriptionModalProps>);
          case ModalName.PauseModal:
            return getPauseModal(modal as IOpenedModal<IPauseModalProps>);
          case ModalName.FundingRequestRejectModal:
            return getFundingRequestRejectModal(modal as IOpenedModal<IFundingRequestRejectModalProps>);
          case ModalName.ConfirmAffiliateChannelHistoryModal:
            return getAffiliateChannelHistoryModal(modal as IOpenedModal<IHistory>);
          case ModalName.TwoFactorAuthenticationModal:
            return getTwoFactorAuthenticationModal(modal as IOpenedModal<null>);
          case ModalName.TwoFactorAuthenticationDisableModal:
            return getTwoFactorAuthenticationDisableModal(
              modal as IOpenedModal<ITwoFactorAuthenticationDisableModalProps>,
            );
          case ModalName.TwoFactorAuthenticationResultModal:
            return getTwoFactorAuthenticationResultModal(
              modal as IOpenedModal<ITwoFactorAuthenticationResultModalProps>,
            );
          case ModalName.DocumentsUploadFilesModal:
            return getDocumentsUploadFilesModal(modal as IOpenedModal<IDocumentsUploadFilesModalProps>);
          case ModalName.XMLModal:
            return getXMLModal(modal as IOpenedModal<IXMLModalProps>);
          case ModalName.DeleteTagModal:
            return getDeleteTagModal(modal as IOpenedModal<IDeleteTagModalProps>);
          case ModalName.RerunModal:
            return getRerunModal(modal as IOpenedModal<IRerunModalProps>);
          case ModalName.RevertApplicationModal:
            return getRevertApplicationModal(modal as IOpenedModal<IRevertApplicationModalProps>);
          case ModalName.DeleteUserModal:
            return getDeleteUserModal(modal as IOpenedModal<IDeleteUserModalProps>);
          case ModalName.ResetDailyCapModal:
            return getResetDailyCapLimitModal(modal as IOpenedModal<null>);
          case ModalName.DomainRestrictionsModal:
            return getDomainRestrictionsModal(modal as IOpenedModal<null>);
          case ModalName.ResendDocumentModal:
            return getResendDocumentModal(modal as IOpenedModal<null>);
          case ModalName.CreateRoleModal:
            return getCreateRoleModal(modal as IOpenedModal<ICreateRoleModalProps>);
          case ModalName.EditRoleModal:
            return getEditRoleModal(modal as IOpenedModal<IEditRoleModalProps>);
          case ModalName.DeleteRoleModal:
            return getDeleteRoleModal(modal as IOpenedModal<IDeleteRoleModalProps>);
          case ModalName.AddNoteModal:
            return getAddNoteModal(modal as IOpenedModal<IAddNoteModalProps>);
          case ModalName.DeleteNoteModal:
            return getDeleteNoteModal(modal as IOpenedModal<IDeleteNoteModalProps>);
          case ModalName.ReasonUploadFilesModal:
            return getReasonUploadFilesModal(modal as IOpenedModal<IReasonUploadFilesModalProps>);
          case ModalName.SolarPanelEditModal:
            return getSolarPanelEditModal(modal as IOpenedModal<ISolarPanelEditModalProps>);
          case ModalName.DeleteFileModal:
            return getDeleteFileModal(modal as IOpenedModal<IDeleteFileModalProps>);
          case ModalName.CreateReasonModal:
            return getCreateReasonModal(modal as IOpenedModal<ICreateReasonModalProps>);
          case ModalName.CancelApplicationModal:
            return getCancelApplicationModal(modal as IOpenedModal<ICancelApplicationModalProps>);
          default:
            return null;
        }
      })}
    </>
  );
};
