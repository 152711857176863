import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { leadsMiddleware, leadsSelector } from 'redux/slices/leads';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import CustomTextArea from '../../shared/CustomInputs/CustomTextArea';
import Title from '../../UI/Title';

export interface ICancelApplicationModalProps {
  id: string;
  applicationId: string;
}

const CancelApplicationModal = ({ id, applicationId }: ICancelApplicationModalProps) => {
  const cancelApplicationLoading = useAppSelector(leadsSelector.cancelApplicationLoading);
  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.CancelApplicationModal));
  };
  const [note, setNote] = useState<string>('');

  const onConfirm = () => {
    if (!cancelApplicationLoading) {
      const trimmedNote = note.trim();

      dispatch(leadsMiddleware.cancelApplication(id!, `${trimmedNote ?? ''}`, applicationId));
    }
  };

  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setNote(value);
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle sx={{ backgroundColor: '#F9F8FE', padding: '14px', marginTop: '-12px' }}>
          <Title
            component="span"
            title="Confirm action"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '16px',
              fontWeight: 700,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography pb={2} pt={3} sx={{ fontSize: '14px' }}>
            Are you sure you want to cancel this application? This action cannot be undone.
          </Typography>
          <CustomTextArea onChange={onNoteChange} label="Notes *" placeholder="Add your message here" />
          <Box display="flex" justifyContent="space-between" marginTop="20px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="error"
              disabled={!note.length}
              loading={cancelApplicationLoading}
              onClick={onConfirm}
              sx={{ backgroundColor: '#FF497F', borderColor: note.length && '#FF497F' }}
            >
              Confirm
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default CancelApplicationModal;
